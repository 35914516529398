<template>
    <div class="b2c-page-bg">
        <div class="b2c-pc-card-header">
            <a href="/nhanuudai">
                <img src="../assets/images/prev-arrow.png" alt=""/>
            </a>
            <span>Nhận thưởng</span>
        </div>
        <div class="b2c-pc-card-content">
            <div class="body-show">
                <div class="success-donate-container">
                    <div class="main-icon__donate-container">
                        <img src="../assets/images/successCheck.png"/>
                    </div>
                    <div class="title__donate-container">
                        Đăng ký nhận thưởng<br/>thành công
                    </div>
                    <div class="info__donate-container">
                        iTel sẽ thực hiện trả thưởng 200k vào tài khoản của số điện thoại {{ $route.query.contactPhone }} sau tối đa 24 giờ làm việc (không tính thứ 7, chủ nhật và các ngày lễ, tết).<br/>Bạn vui lòng kiểm tra tin nhắn từ iTel hoặc soạn *101# để kiểm tra số dư tài khoản nhé.
                    </div>
                    <div class="col-md-12 d-flex justify-content-center">
                        <a class="btn-home-return" href="/">
                            Về trang chủ
                        </a>
                    </div>
                    <div class="sub-info">
                        Khi cần hỗ trợ, Bạn vui lòng liên hệ số CSKH <br/>iTel 0877 087 087 (0đ cho thuê bao iTel)
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {BCard, BCardText, BCardHeader, BFormInput} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";

extend("VNPhone", (value) => {
  const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
  return value.match(regexPhoneNumber) ? true : false;
});

export default {
    components: {
        BCard,
        BCardText,
        BCardHeader,
        ValidationProvider, 
        ValidationObserver,
        BFormInput
    },
    data() {
        return {
          required,
          min, 
          email,
          step: 1,
          userInfo: {
            fullName: "",
            ContactPhone: "",
            email: "",
          }
        };
    },
    methods: {
        openVPBank(){
            const a = document.createElement('a')
            a.href = "https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb"
            a.target = "_blank"
            a.click()
        },
        async onOpenCreaditCard(){
            var validate = await this.$refs.infoValid.validate().then((success) => {
                return success;
            });
            if (!validate) {
                this.$toast.warning(
                    "Vui lòng nhập đủ thông tin",
                    {icon: true, closeButton: "button"}
                );
                return;
            }
        }
    }
}
</script>
<style lang="scss">
    .b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-pc-card-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 120px;

    display: flex;
    align-items: center;
    column-gap: 22px;
    img{
        width: 9px;
        cursor: pointer;
    }
    span{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 11px;
        color: #424242;
    }
}
@media screen and (max-width: 768px){
    .b2c-pc-card-content{
        padding-top: 19px;
        padding: 0;
    }
    .b2c-pc-card-header{
        background: #FFFFFF;
        padding-left: 24px;
        span{
            font-size: 18px;
        }
    }
    .list-vpbank-info{
        padding-left: 45px;
    }
    .open-credit-form{
        border-radius: 0;
    }
    .open-credit-form{
        height: auto;
    }
    .pb-sp-040{
        padding-bottom: 40px;
    }
    .col-step-1{
        display: none;
    }
    .col-step-2{
        display: block;
        padding: 0px 24px !important;
        .open-credit-form{
            height: fit-content;
            overflow-y: hidden;
            .open-credit-form-btn-continue{
                margin-top: 16px;
            }
        }
        .card{
            height: fit-content !important;
            background: transparent;
            box-shadow: none;
        }
        .card-body{
            position: relative;
            height: fit-content;
            top: -40px;
            border-radius: 1rem;
            .card-text{
                border-radius: 1rem;
                .open-credit-form{
                    border-radius: 1rem;
                }
            }
        }
    }
}
@media screen and (min-width: 577px){
  .text-container{
    padding: 0 87px;
  }
  .b2c-pc-card-content{
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.body-show {
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-donate-container {
    width: 504px;
    min-height: 500px;
    background: #FFFFFF;
    box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 10px 0;
    padding: 40px 70px;
}
.main-icon__donate-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-icon__donate-container >img {
    width: 115px;
    height: 115px;
}
.title__donate-container {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-top: 12px;
    color: #424242;
    width: 100%;
    text-align: center;
}

.info__donate-container {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 18px;
    color: #424242;
    width: 100%;
    text-align: center;
}

.btn-home-return {
    background: #ED1F24;
    border-radius: 26.8042px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 11px;
    text-align: center;
    color: #FFFFFF !important;
    padding: 15px 50px;
    margin-top: 20px;
}
.sub-info {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #424242;
    margin-top: 30px;
}
</style>